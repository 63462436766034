import { Controller } from "@hotwired/stimulus"
import autosize from "autosize"

export default class extends Controller {
  static targets = ["submitButton", "titleField", "contentField"]

  initialize() {
    this.timer = null
    this.lastSubmitAt = null
  }

  connect() {
    autosize(this.titleFieldTarget)
    autosize(this.contentFieldTarget)
  }

  disconnect() {
    autosize.destroy(this.titleFieldTarget)
    autosize.destroy(this.contentFieldTarget)
  }

  submit() {
    this.submitButtonTarget.click()
    this.lastSubmitAt = Date.now()
    this.toggleWarningBeforeLeaving(false)
  }

  submitNow() {
    clearTimeout(this.timer)
    this.submit()
  }

  submitLater() {
    const delay = 3000
    const delayMax = 30000

    this.toggleWarningBeforeLeaving(true)

    clearTimeout(this.timer)
    const submitFn = () => { this.submit() }
    if (!this.lastSubmitAt || Date.now() - this.lastSubmitAt < delayMax) {
      this.timer = setTimeout(submitFn, delay)
    } else {
      submitFn()
    }
  }

  toggleWarningBeforeLeaving(enable) {
    window.onbeforeunload = enable ? () => { return true } : null
  }
}
