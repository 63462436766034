import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr"

export default class extends Controller {
  static targets = ["input"]

  connect() {
    flatpickr(this.inputTarget, {
      enableTime: true,
      time_24hr: true,
      dateFormat: "Y-m-d H:i",

      // workaround for clearing field
      onChange: function(selectedDates, dateStr, instance){
        if (dateStr === '') { instance.close(); }
      }
    })
  }
}
