import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["content"]

  toggle(event) {
    event.preventDefault()
    const klass = this.data.get("klass")
    this.contentTarget.classList.toggle(klass)
  }
}
