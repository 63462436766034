import { Controller } from "@hotwired/stimulus"
import { createPopper } from "@popperjs/core"

export default class extends Controller {
  static targets = ["toggler", "menu"]

  connect() {
    this.activeClass = "active"
    this.popper = null
  }

  disconnect() {
    this.forceHide()
  }

  toggle(event) {
    event.preventDefault()

    this.destroyPopper()

    if (this.menuTarget.classList.toggle(this.activeClass)) {
      this.popper = createPopper(this.togglerTarget, this.menuTarget, {
        placement: this.data.get("placement"),
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 4],
            },
          },
        ],
      })
    }
  }

  hide(event) {
    if (this.element.contains(event.target) === false) {
      this.forceHide()
    }
  }

  forceHide() {
    this.destroyPopper()
    this.menuTarget.classList.remove(this.activeClass)
  }

  destroyPopper() {
    if (this.popper) {
      this.popper.destroy()
      this.popper = null
    }
  }
}
